<template lang="pug" functional>
  div
    v-card.planning-skeleton(
      v-for="i in props.count"
      :key="i")
      .planning-table__row
        .planning-table__row-head
          .planning-table__row-head-cell.planning-table__row-head-cell--big
            .text-skeleton
          .planning-table__row-head-cell.planning-table__row-head-cell--big
            .text-skeleton
          .planning-table__row-head-cell
            .text-skeleton
          .planning-table__row-head-cell
            .text-skeleton

</template>

<script>
export default {
  name: 'PlanningTableRowSkeleton',

  props: {
    count: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/sass/abstracts/_mixins.scss';
  @import '@/sass/abstracts/_variables.scss';

  .planning-table__row {

    &-head {
      padding: .8rem 1.5rem;
      position: relative;
      display: flex;
      align-items: center;
      height: 4rem;

      &-cell {
        padding-right: 1rem;
        width: 17%;
        max-width: 25rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:last-child {
          padding-right: 0;
        }

        &--big {
          width: 33%;
        }
      }
    }
  }

  .planning-skeleton {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .text-skeleton {
    height: 2rem;
    width: 60%;
    border-radius: .8rem;

    @include skeleton;
  }
</style>
